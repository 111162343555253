<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>

      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto d-flex flex-column justify-content-center align-items-center position-static">
          <b-img :src="appLogoImage" alt="logo" class="logo-login-page position-absolute" />

          <validation-observer ref="loginValidation" class="w-100">
            <h2 class="text-left">Zresetuj hasło</h2>

            <b-form class="auth-login-form mt-2" @submit.prevent ref="formContainer">
              <b-form-group label="Email" label-for="login-email">
                <validation-provider #default="{ errors }" name="Email" rules="required|email">
                  <b-form-input
                    id="login-email"
                    v-model="form.email"
                    :state="errors.length > 0 ? false : null"
                    name="login-email" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider #default="{ errors }" name="Hasło" rules="required|password" vid="password">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="password"
                      v-model="form.password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password" />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Potwierdzenie hasła"
                  rules="required|confirmed:password">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="password-confirmation"
                      v-model="form.password_confirmation"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password-confirmation" />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button type="submit" variant="primary" block @click="validationForm"> Zresetuj hasło </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '@/store/index';
import NotificationMixin from '@/mixins/NotificationMixin';
import { resetPassword } from '../http/auth';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import { $themeConfig } from '@themeConfig';

export default {
  mixins: [togglePasswordVisibility, NotificationMixin],

  data() {
    const { appLogoImage } = $themeConfig.app;

    return {
      form: {
        email: '',
        password: '',
        password_confirmation: '',
      },
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      appLogoImage,
    };
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },

  methods: {
    async resetPassword() {
      const userData = {
        email: this.form.email,
        password: this.form.password,
        password_confirmation: this.form.password_confirmation,
        token: this.$route.params.token,
      };

      const loader = this.$loading.show();

      await resetPassword(userData)
        .then(() => {
          this.$router.push('/login');
          this.$toast.success('Hasło zostało zresetowane');
        })
        .catch(() => {
          this.showErrorNotification('Coś poszło nie tak', 'Spróbuj ponownie później');
        })
        .finally(() => {
          loader.hide();
        });
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.resetPassword();
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.logo-login-page {
  top: 1.5rem;
  right: 4.5rem;
  width: 6.5rem;
  height: 6.5rem;
}
</style>
